input::-webkit-input-placeholder {
    color: #999;
}

.range {
    /* margin: 0 30vw; */
    display: block;
}

#form-area-container {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    justify-content: center;
    gap: 10px 20px;
    width: 100%;
    color: #111111;
}

.form-area {
    width: 30vw;
    background-color: #eee;
    padding: 0 1em;
}